var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-navigation-drawer',{attrs:{"app":"","disable-route-watcher":this.$router.currentRoute.path.includes('search')},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.navigation'))+" ")])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"nav":""}},_vm._l((_vm.menuItems),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":"","to":item.routerLink}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1),_c('v-app-bar',{attrs:{"app":""}},[_c('v-btn',{staticClass:"ml-1",attrs:{"icon":""},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v(" menu ")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),(this.$router.currentRoute.name == 'SearchResult' || this.$router.currentRoute.name == 'Search')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){_vm.showToolTip = !_vm.showToolTip}}},'v-icon',attrs,false),on),[_vm._v(" info ")])]}}],null,false,1067617417),model:{value:(_vm.showToolTip),callback:function ($$v) {_vm.showToolTip=$$v},expression:"showToolTip"}},[(this.$router.currentRoute.name == 'SearchResult')?_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.helpSearchResult'))+" ")]):_vm._e(),(this.$router.currentRoute.name == 'Search')?_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.helpSearch'))+" ")]):_vm._e()]):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.$vuetify.theme.dark = !_vm.$vuetify.theme.dark}}},[_c('v-icon',[_vm._v("brightness_6")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("translate")])],1)]}}])},[_c('v-list',_vm._l((_vm.languages),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateLanguage(item.code)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1),(_vm.username != '')?_c('v-menu',{attrs:{"top":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"indigo","size":"36"}},'v-avatar',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.username.charAt(0)))])])]}}],null,false,2646237506)},[_c('v-btn',{on:{"click":function($event){return _vm.logout()}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.logout'))+" ")])],1):_vm._e()],1),_c('v-main',[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-sheet',{attrs:{"rounded":"lg"}},[_c('v-container',[(_vm.autoLoginDone)?_c('router-view',{attrs:{"desktop":_vm.desktop,"api":_vm.api,"username":_vm.username,"songs":_vm.songs},on:{"fetchData":_vm.autoLogin,"search":_vm.onSearch,"nextQuery":_vm.onNextQuery,"httpError":_vm.showSnackbar,"showSnackbar":_vm.showSnackbar}}):_vm._e()],1)],1)],1)],1)],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbarVisible = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.close'))+" ")])]}}]),model:{value:(_vm.snackbarVisible),callback:function ($$v) {_vm.snackbarVisible=$$v},expression:"snackbarVisible"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }