import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import en from '@/i18n/vuetify/en'
import nl from '@/i18n/vuetify/nl'
import eo from '@/i18n/vuetify/eo'
import no from '@/i18n/vuetify/no'

export default new Vuetify({
    theme: { dark: true },
    lang: {
      locales: { en, nl, eo, no },
      current: 'en',
  },
});
