<template>
  <div>
    <v-form>
      <v-row>
        <v-col
          cols="2"
        >
          <v-switch
            v-model="bpmEnabled"
            class="mr-4"
          />
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model="bpm"
            label="BPM"
            :disabled="!bpmEnabled"
          />
        </v-col>
        <v-col cols="4">
          <v-switch
            v-model="jazzMode"
            :label="jazzMode ? jazzModeTranslation : popModeTranslation "
          />
        </v-col>
      </v-row>
      <v-text-field
        v-for="i in queries.length"
        :key="i"
        v-model="queries[i-1]"
        :label="`${barTranslation} #${i}`"
        :append-outer-icon="queries.length == i ? 'add_circle' : ''"
        :class="i % 4 == 0 ? 'mb-7' : ''"
        @click:append-outer="newQuery"
        @mousedown="selectedQuery = i"
      />
      <div>
        <v-row
          v-for="(key, index) in keys"
          :key="index"
          class="ma-1"
          style="display: flex; justify-content: center;"
        >
          <v-btn
            v-for="(k, i) in key"
            :key="i"
            class="ma-1 text-none"
            depressed
            :small="k['small']"
            color="primary"
            @click="updateQuery(k)"
          >
            {{ k['text'] }}
          </v-btn>
        </v-row>
        <v-btn
          block
          class="my-2"
          color="primary"
          @click="resetQuery()"
        >
          {{ $vuetify.lang.t('$vuetify.reset') }}
        </v-btn>
        <v-btn
          block
          class="my-2"
          color="primary"
          @click="search"
        >
          {{ $vuetify.lang.t('$vuetify.search') }}
        </v-btn>
        <v-btn
          v-if="username !== ''"
          block
          class="my-2"
          color="primary"
          @click="addToLibrary"
        >
          {{ $vuetify.lang.t('$vuetify.add_to_lib') }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
  export default {
    props: {
      username: {
        type: String,
        default: ""
      },
      desktop: {
        type: Boolean,
        default: false
      },
      songs: {
        type: Array,
        default: function () { return [] }
      },
      api: {
        type: String,
        default: ""
      }
    },
    data: () => ({
      queries: [""],
      selectedQuery: 1,
      bpm: undefined,
      bpmEnabled: false,
      jazzMode: true,
    }),
    computed: {
      language: function () {
        return this.$vuetify.lang.current
      },
      barTranslation: function () {
        return this.$vuetify.lang.t('$vuetify.bars')
      },
      jazzModeTranslation: function () {
        return this.$vuetify.lang.t('$vuetify.jazzMode')
      },
      popModeTranslation: function () {
        return this.$vuetify.lang.t('$vuetify.popMode')
      },
      keys: function () {
        let keys = [[{small: false, text: 'A'}, {small: false, text: 'B'}, {small: false, text: 'C'}, {small: false, text: 'D'}, {small: false, text: 'E'}, {small: false, text: 'F'}, {small: false, text: 'G'}], [{small: true, text: '♭'}, {small: true, text: '♭♭'}, {small: true, text: ''}, {small: true, text: '♯'}, {small: true, text: '♯♯'}], [{small: false, text: 'Δ'}, {small: false, text: '7'}, {small: false, text: 'mi7'}, {small: false, text: 'ø'}]]
        if (this.language === "no") {
          keys[0].push({small: false, text: 'H'})
        }
        if (this.jazzMode) {
          keys[2] = keys[2].concat([{small: false, text: 'Δ+5'}, {small: false, text: 'miΔ'}, {small: false, text: 'o'}, {small: false, text: '7♭9'}, {small: false, text: '7♭13'}, {small: false, text: '7♯11'}, {small: false, text: '7alt'}])
        }
        return keys
      }
    },
    watch: {
      jazzMode: function () {
        let self = this
        this.axios.put(`${this.api}/user/`, {
          "search_mode": self.jazzMode ? "jazz" : "pop"
        }).catch(function() {
          //if user not logged in, store jazzMode as cookie
          document.cookie = `jazzMode=${self.jazzMode};`
        })
      }
    },
    mounted: function () {
      let self = this
      this.axios.get(`${this.api}/user/`).then(function(response) {
        self.jazzMode = response.data.search_mode == "jazz" ? true : false
      }).catch(function() {
        self.jazzMode = self.getCookie("jazzMode") === "true" ? true : false
      })
    },
    methods: {
      getCookie: function(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      },
      search: function() {
        // TODO: queries verifiëren
        if (this.bpm && this.bpm > 500){
          this.$emit("showSnackbar", this.$vuetify.lang.t('$vuetify.max_bpm'))
          return
        }
        if (this.bpmEnabled && this.bpm && this.bpm > 0){
          this.$emit("search", [this.queries, this.bpm, this.jazzMode])
        } else {
          this.$emit("search", [this.queries, undefined, this.jazzMode])
        }
      },
      updateQuery: function (x) {
        this.queries[this.selectedQuery - 1] += x.text
        this.$forceUpdate()
      },
      newQuery: function () {
        this.queries.push("")
        this.selectedQuery = this.queries.length
      },
      resetQuery: function () {
        this.queries[this.selectedQuery - 1] = ''
        this.$forceUpdate()
      },
      addToLibrary: function () {
        let self = this;
        let s = this.songs
        let o = {
          "name": this.$vuetify.lang.t('$vuetify.nameless'),
          "queries": this.queries,
          "bpm": this.bpm,
          "bpmEnabled": this.bpmEnabled,
          "jazzMode": this.jazzMode
        }
        s.push(o)
        this.axios.post(`${this.api}/song/`, s).then(function() {
          self.$emit("showSnackbar", self.$vuetify.lang.t('$vuetify.song_added'))
        }).catch(function (error){
          self.$emit("showSnackbar", error.response.data)
        })
      },
    }
  }
</script>
