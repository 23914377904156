import { no } from 'vuetify/lib/locale'

export default {
  ...no,

  title: "Harmonienes Periodesystem",
  name: "Navn",
  bars: "Takt",
  reset: "Nullstill",
  search: "Søk",
  navigation: "Navigasjon",
  library: "Bibliotek",
  agree_privacy: "Ved å klikke på «Registrer» godkjenner du våre ",
  privacy_policy: "personvernregler",
  jazzMode: "Jazzmodus",
  popMode: "Popmodus",
  nameless: "Navnløs",
  add_new_song: "Legge til nytt nummer",
  add_to_lib: "Legge til i biblioteket",
  logout: "Logg ut",
  login: "Logg inn",
  signup: "Registrer",
  username: "Brukernavn",
  pw: "Passord",
  delete: "Slett",
  save: "Lager",
  no_results: "Ingen resultater funnet.",
  max_bpm: "Maksimal mulig BPM er 500.",
  song_added: "Nummer lagd til i biblioteket!",
  welcome: "Velkommen!",
  account_created: "Kontoen opprettet!",
  deleted: "Slettet!",
  saved: "Lagret!",
  required: "Forpliktet.",
  close: "Lukk",
  helpSearch: "For en ii-V-progresjon er det lurt å sjekke om det er i dur (7 eller 7alt) eller i moll (7♭9). Det holder å kun trykke på 5. trinn siden 2. trinn står i samme tabellen.",
  helpSearchResult: "Man kan scrolle opp og ned, swipe til høyre for flere funksjoner av søkte akkorder og swipe til venstre for enharmonisk identiske tabeller. Så hvis man ønsker å «swipe tilbake» skal man swipe videre.",
  about: "Om appen",
  aboutPageOne: "Velkommen til Harmonienes Periodesystem ",
  aboutPageTwo: "Med denne appen trenger du ikke lenger å huske alle de kule akkordsubstisjonene mens du improviserer. Nå kan du bare spille hvilken som helst akkord på skjermen. I tillegg kan komponister bytte mellom pop- og jazzmodus  og se hvilke muligheter som finnes for hvilken som helst akkord.",
  aboutPageThree: "Legg til låter ved å fylle inn hver takt i Søkfanen (trykk på pluss). Hvis du vil beholde det du legger til er det lurt å opprette en konto i Bibliotekfanen.",
  aboutPageFour: "Slå på BPM-funksjonen (x0,75 for vals) og kjør …  Hvis appen ligger foran eller etter med det du hører, kan det løses ved å trykke fram eller tilbake, som er den eneste måten å navigere gjennom sangen på mens BPM-funksjonen er slått av. Mens en låt spiller kan du fortsette med å skrolle og swipe hvis du for eksempel ønsker å jobbe med dobbeltkryss eller dobbel b.",
  aboutPageFive: "Appen funksjonerer ikke optimalt i Google Chrome. Vi anbefaler å bruke appen i Firefox.",
  aboutPageSix: "Donasjoner er ønsket velkommen."
}
