import { en } from 'vuetify/lib/locale'

export default {
  ...en,

  title: "The Periodic Table of Harmonies",
  name: "Name",
  bars: "Bar",
  reset: "Reset",
  search: "Search",
  navigation: "Navigation",
  library: "Library",
  agree_privacy: "By clicking on “Sign up” you agree with our ",
  privacy_policy: "privacy policy",
  jazzMode: "Jazz mode",
  popMode: "Pop mode",
  nameless: "Nameless",
  add_new_song: "Add new song",
  add_to_lib: "Add to library",
  logout: "Log out",
  login: "Log in",
  signup: "Sign up",
  username: "Username",
  pw: "Password",
  delete: "Delete",
  save: "Save",
  no_results: "No results found.",
  max_bpm: "The maximum BPM is 500.",
  song_added: "Song added to library!",
  welcome: "Welcome!",
  account_created: "Account created!",
  deleted: "Deleted!",
  saved: "Saved!",
  required: "Required.",
  close: "Close",
  helpSearch: "For a ii-V progression it is advisable to check whether it is in major (7 or 7alt) or in minor (7♭9), and to click only on the fifth degree since the second degree is in the same table.",
  helpSearchResult: "You can scroll up and down, swipe right for more functions of your searched chord and swipe left for enharmonically identical tables. So if you want to undo your swipe you need to swipe further.",
  about: "About",
  aboutPageOne: "Welcome to The Periodic Table of Harmonies",
  aboutPageTwo: "With this app on your music stand you don’t need to remember all the hip chord substitutes anymore while improvising. Just play over any chord on the screen! Composers could look up separate chords in the Jazz mode or the Pop mode to investigate all the possibilities.",
  aboutPageThree: "Add a song in the Search page by filling in every other chord of the song at the little plus sign. It’s advisable to make an account in the Library, so your work won’t get lost!",
  aboutPageFour: "Turn on the BPM function (times 0.75 for waltzes) and go … If the app is behind or ahead of what you’re hearing you could click to the next or previous chord, which is the only way to navigate through the song if the BPM function is disabled. While a song is playing you could keep scrolling and swiping if you would like to read a lot of double flats and sharps for example.",
  aboutPageFive: "The app doesn’t function properly in Google Chrome. We advise you to use this app in Firefox.",
  aboutPageSix: "Donations are welcome."
}
