<template>
  <div>
    <v-form>
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="username"
              :rules="[rules.required, rules.min]"
              :label="$vuetify.lang.t('$vuetify.username')"
              counter
              @click:append="showPassword = !showPassword"
            />
            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              :rules="[rules.required, rules.min]"
              :type="showPassword ? 'text' : 'password'"
              :label="$vuetify.lang.t('$vuetify.pw')"
              hint="At least 6 characters"
              counter
              @click:append="showPassword = !showPassword"
            />
          </v-col>
        </v-row>
        <v-row class="mb-2 mx-2">
          <p> {{ $vuetify.lang.t('$vuetify.agree_privacy') }} <a @click="togglePrivacyPolicy"> {{ $vuetify.lang.t('$vuetify.privacy_policy') }}</a>.</p>
        </v-row>
        <v-btn
          class="my-2"
          block
          color="primary"
          @click="login"
        >
          {{ $vuetify.lang.t('$vuetify.login') }}
        </v-btn>
        <v-btn
          class="mt-2"
          block
          color="primary"
          @click="signup"
        >
          {{ $vuetify.lang.t('$vuetify.signup') }}
        </v-btn>
      </v-container>
      <PrivacyPolicy
        v-if="showPrivacyPolicy"
      />
    </v-form>
  </div>
</template>

<script>
  import PrivacyPolicy from './PrivacyPolicy.vue'
  export default {
    components: {
      PrivacyPolicy
    },
    props: {
      api:{
        type: String,
        default: ""
      }
    },
    data: () => ({
      username: "",
      password: "",
      showPassword: false,
      showPrivacyPolicy: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 6 || 'At least 6 characters',
      },
    }),
    methods: {
      togglePrivacyPolicy: function () {
        this.showPrivacyPolicy = !this.showPrivacyPolicy;
      },
      login: function () {
        let self = this
        this.axios.post(`${this.api}/auth/login`, {
          name: this.username,
          pw: this.password
        }).then(function() {
          self.$emit("fetchData")
          self.$emit("showSnackbar", self.$vuetify.lang.t('$vuetify.welcome'))
        }).catch(function (error){
          self.$emit("httpError", error.response.data)
        })
      },
      signup: function () {
        let self = this
        this.axios.post(`${this.api}/user`, {
          name: this.username,
          pw: this.password
        }).then(function() {
          self.$emit("fetchData")
          self.$emit("showSnackbar", self.$vuetify.lang.t('$vuetify.account_created'))
        }).catch(function (error){
          self.$emit("httpError", error.response.data)
        })
      },
    }

  }
</script>
