export default {
  title: "La Harmoniaro",
  name: "Nomo",
  bars: "Mezuro",
  reset: "Restarigi",
  search: "Serĉi",
  navigation: "Navigado",
  library: "Biblioteko",
  agree_privacy: "Klakante “Rigistri” vi akceptas nian ",
  privacy_policy: "privatecan politikon",
  jazzMode: "Ĵazreĝimo",
  popMode: "Popreĝimo",
  nameless: "Sennoma",
  add_new_song: "Aldoni novan muzikaĵon",
  add_to_lib: "Enbibliotekigi",
  logout: "Elsaluti",
  login: "Ensaluti",
  signup: "Rigistri",
  username: "Uzantnomo",
  pw: "Pasvorto",
  delete: "Forigi",
  save: "Konservi",
  no_results: "Neniuj rezultoj trovitaj.",
  max_bpm: "Maksimuma BPM estas 500.",
  song_added: "Kantaĵo enbibliotekigita!",
  welcome: "Bonvenon!",
  account_created: "Konto kreita!",
  deleted: "Forigita!",
  saved: "Konservita!",
  required: "Devigis.",
  close: "Fermu",
  helpSearch: "Ĉe 2-5-progresio eble antaŭe pripensu ĉu ĝi estas maĵora (7 aŭ 7alt) aŭ minora (7♭9). Sufiĉas alklaki nur la kvinan ŝtupon, ĉar la dua ŝtupo jam estas en la sama tabelo.",
  helpSearchResult: "Eblas rulumi supren kaj malsupren. Ŝovumu dekstren por vidi pliajn funkciojn de la serĉata akordo kaj ŝovumu maldekstren por vidi enharmonie identajn tabelojn. Do ne eblas malfari ŝovumojn, eblas nur pluŝovumi.",
  about: "Pri",
  aboutPageOne: "Bonvenon al La Harmoniaro",
  aboutPageTwo: "Kun ĉi tiu apo ĉemane vi dumimprovize neniam plu bezonas fosi en via memoro por trovi mojosajn akordsubstituaĵojn. Nun vi povas ĉirkaŭludi ĉiun ajn surekranan akordon. Ankaŭ komponante, vi povas esplori ĉiujn eblojn por ĉiu unuopa akordo en la Ĵazreĝimo aŭ la Popreĝimo.",
  aboutPageThree: "Sur la Serĉpaĝo vi povas jene aldoni kanton: alklaku la plus-signon por enigi mezuron post mezuro ĉiun akordon de la skemo. Kreinte konton, vi povas konservi viajn enigaĵojn en la Bibliotekon. Tiel via laboro ne perdiĝos.",
  aboutPageFour: "Simple ŝaltu la BPM-funkcion (oble 0,75 por valsoj) kaj ek ... Kaj se la apo ne sinkrone ludas la aŭdatan kanton, eblas klake iri antaŭen aŭ malantaŭen. Se vi malŝaltas la BPM-funkcion, tio estas la sola maniero tranavigi la kanton.",
  aboutPageFive: "Ĉi tiu apo ne tute bone funkcias en la foliumilo Google Chrome. Ni rekomendas uzi Firefox por nia apo.",
  aboutPageSix: "Monaj subtenoj estas bonvenaj."
}
