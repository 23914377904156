<template>
  <div>
    <v-expansion-panels
      v-model="panel"
    >
      <v-expansion-panel
        v-for="(item,i) in songs"
        :key="i"
      >
        <v-expansion-panel-header expand-icon="expand_more">
          {{ songs[i].name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form>
            <v-text-field
              v-model="songs[i].name"
              :label="nameTranslation"
            />
            <v-btn
              block
              class="ma-2"
              color="primary"
              @click="search(i)"
            >
              {{ $vuetify.lang.t('$vuetify.search') }}
            </v-btn>
            <v-row>
              <v-col cols="11">
                <v-switch
                  v-model="songs[i].jazzMode"
                  :label="songs[i].jazzMode ? jazzModeTranslation : popModeTranslation "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="1"
                class="mr-3"
              >
                <v-switch
                  v-model="songs[i].bpmEnabled"
                />
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="songs[i].bpm"
                  label="BPM"
                  :disabled="!songs[i].bpmEnabled"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-for="k in songs[i].queries.length"
              :key="k"
              v-model="songs[i].queries[k-1]"
              :label="`${barTranslation} #${k}`"
              :append-outer-icon="songs[i].queries.length == k ? 'add_circle' : ''"
              :class="k % 4 == 0 ? 'mb-7' : ''"
              @click:append-outer="newQuery(i)"
              @mousedown="selectedQuery = k"
            />
            <div class="text-center">
              <v-row
                v-for="(key, index) in keys"
                :key="index"
                class="ma-3"
                style="display: flex; justify-content: center;"
              >
                <v-btn
                  v-for="(k, j) in key"
                  :key="j"
                  :small="k['small']"
                  class="ma-1 text-none"
                  depressed
                  color="primary"
                  @click="updateQuery(k, i)"
                >
                  {{ k['text'] }}
                </v-btn>
              </v-row>
              <v-btn
                block
                class="ma-2"
                color="primary"
                @click="resetQuery(i)"
              >
                {{ $vuetify.lang.t('$vuetify.reset') }}
              </v-btn>
              <v-btn
                block
                class="ma-2"
                color="primary"
                @click="remove(i)"
              >
                {{ $vuetify.lang.t('$vuetify.delete') }}
              </v-btn>
              <v-btn
                block
                class="ma-2"
                color="primary"
                @click="save()"
              >
                {{ $vuetify.lang.t('$vuetify.save') }}
              </v-btn>
            </div>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn
      block
      class="ma-2"
      color="primary"
      @click="goToSearch"
    >
      {{ $vuetify.lang.t('$vuetify.add_new_song') }}
    </v-btn>
  </div>
</template>

<script>
  export default {
    props: {
      api: {
        type: String,
        default: ""
      },
      songs: {
        type: Array,
        default: function () { return [] }
      },
    },
    data: () => ({
      panel: 0
    }),
    computed: {
      nameTranslation: function () {
        return this.$vuetify.lang.t('$vuetify.name')
      },
      barTranslation: function () {
        return this.$vuetify.lang.t('$vuetify.bars')
      },
      jazzModeTranslation: function () {
        return this.$vuetify.lang.t('$vuetify.jazzMode')
      },
      popModeTranslation: function () {
        return this.$vuetify.lang.t('$vuetify.popMode')
      },
      language: function () {
        return this.$vuetify.lang.current
      },
      keys: function () {
        let keys = [[{small: false, text: 'A'}, {small: false, text: 'B'}, {small: false, text: 'C'}, {small: false, text: 'D'}, {small: false, text: 'E'}, {small: false, text: 'F'}, {small: false, text: 'G'}], [{small: true, text: '♭'}, {small: true, text: '♭♭'}, {small: true, text: ''}, {small: true, text: '♯'}, {small: true, text: '♯♯'}], [{small: false, text: 'Δ'}, {small: false, text: '7'}, {small: false, text: 'mi7'}, {small: false, text: 'ø'}]]
        if (this.language === "no") {
          keys[0].push({small: false, text: 'H'})
        }
        if (this.songs[this.panel] && this.songs[this.panel].jazzMode) {
          keys[2] = keys[2].concat([{small: false, text: 'Δ+5'}, {small: false, text: 'miΔ'}, {small: false, text: 'o'}, {small: false, text: '7♭9'}, {small: false, text: '7♭13'}, {small: false, text: '7♯11'}, {small: false, text: '7alt'}])
        }
        return keys
      }
    },
    methods: {
      emitHttpError: function(e){
        this.$emit("httpError", e)
      },
      newQuery: function (i) {
        this.songs[i].queries.push("")
        this.selectedQuery = this.songs[i].queries.length
      },
      search: function(i) {
        // TODO: queries verifiëren
        if (this.songs[i].bpmEnabled && this.songs[i].bpm && this.songs[i].bpm > 0){
          this.$emit("search", [this.songs[i].queries, this.songs[i].bpm, this.songs[i].jazzMode])
        } else {
          this.$emit("search", [this.songs[i].queries, undefined, this.songs[i].jazzMode])
        }
      },
      updateQuery: function (x, i) {
        this.songs[i].queries[this.selectedQuery - 1] += x.text
        this.$forceUpdate()
      },
      resetQuery: function (i) {
        this.songs[i].queries[this.selectedQuery - 1] = ''
        this.$forceUpdate()
      },
      goToSearch: function () {
        this.$router.push("/")
      },
      remove: function (i) {
        let self = this;
        this.songs.splice(i, 1)
        this.axios.post(`${this.api}/song/`, this.songs).then(function() {
          self.$emit("showSnackbar", self.$vuetify.lang.t('$vuetify.deleted'))
        }).catch(function (error){
          self.$emit("showSnackbar", error.response.data)
        })
      },
      save: function () {
        let self = this;
        this.axios.post(`${this.api}/song/`, this.songs).then(function() {
          self.$emit("showSnackbar", self.$vuetify.lang.t('$vuetify.saved'))
        }).catch(function (error){
          self.$emit("showSnackbar", error.response.data)
        })
      },
    }
  }
</script>
