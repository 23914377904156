import { nl } from 'vuetify/lib/locale'

export default {
  ...nl,

  title: "Het Periodiek Systeem der Harmonieën",
  name: "Naam",
  bars: "Maat",
  reset: "Resetten",
  search: "Zoeken",
  navigation: "Navigatie",
  library: "Bibliotheek",
  agree_privacy: "Door te klikken op “Registreren” gaat u akkoord met ons ",
  privacy_policy: "privacybeleid",
  jazzMode: "Jazzmodus",
  popMode: "Popmodus",
  nameless: "Naamloos",
  add_new_song: "Nieuw nummer toevoegen",
  add_to_lib: "Toevoegen aan bibliotheek",
  logout: "Log uit",
  login: "Log in",
  signup: "Registreren",
  username: "Gebruikersnaam",
  pw: "Wachtwoord",
  delete: "Verwijderen",
  save: "Opslaan",
  no_results: "Geen resultaten gevonden",
  max_bpm: "Maximaal mogelijke BPM is 500.",
  song_added: "Nummer toegevoegd aan bibliotheek!",
  welcome: "Welkom!",
  account_created: "Account aangemaakt!",
  deleted: "Verwijderd!",
  saved: "Opgeslagen!",
  required: "Verplicht.",
  close: "Sluiten",
  helpSearch: "Voor een ii-V-verbinding is het handig om alvast te bedenken of het in majeur (7 of 7alt) staat of in mineur (7♭9) en dan alleen de vijfde trap aan te klikken aangezien de tweede trap in dezelfde tabel staat.",
  helpSearchResult: "Je kan naar boven en naar beneden scrollen, naar rechts swipen voor andere functies van je gezochte akkoord en naar links swipen voor enharmonisch gelijke tabellen. Dus als je wil “terugswipen” moet je juist verderswipen.",
  about: "Over de app",
  aboutPageOne: "Welkom in Het Periodiek Systeem der Harmonieën",
  aboutPageTwo: "Met deze app op je lessenaar hoef je tijdens het improviseren nooit meer in je geheugen te graven naar hippe akkoordsubtituties. Nu kun je gewoon elk willekeurig akkoord op je scherm omspelen! Ook kun je voor je eigen componeerwerk van elk afzonderlijk akkoord in de Jazzmodus of de Popmodus onderzoeken welke mogelijkheden er zijn.",
  aboutPageThree: "Voeg een liedje toe door in de Zoekpagina maat voor maat (klik op het plusje) elk akkoord van het schema in te voeren. Een account maken in de Bibliotheek is handig, zodat je werk niet verloren gaat!",
  aboutPageFour: "Schakel de BPM-functie in (keer 0,75 voor walsjes) en gaan ... Als de app voor of achter loopt met wat je hoort, kun je desgewenst naar voren en naar achteren klikken. Dit is overigens de enige manier om door je liedje te navigeren wanneer de BPM-functie is uitgeschakeld. Tijdens het afspelen van een liedje kun je blijven scrollen en swipen als je bijvoorbeeld wilt werken met dubbelkruizen of -mollen.",
  aboutPageFive: "De app werkt niet optimaal in Google Chrome. Wij raden je aan om deze app te gebruiken in Firefox.",
  aboutPageSix: "Donaties zijn welkom."
}
